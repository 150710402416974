import { EntityLookupOptions, ShortUrl } from '@air/api';
import { ShortUrl as ShortUrlType } from '@air/api/types';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';

const PUBLIC_FILTERS_ENTITIES = 'PUBLIC_FILTERS_ENTITIES';

export const getPublicFiltersEntitiesKey = (shortId: ShortUrlType['id'], options: EntityLookupOptions) => [
  PUBLIC_FILTERS_ENTITIES,
  { shortId, ...options },
];

export const useGetPublicFiltersEntities = (options: EntityLookupOptions) => {
  const { shortId } = useShortIdContext();

  return useQuery({
    queryKey: getPublicFiltersEntitiesKey(shortId, options),

    queryFn: () => ShortUrl.getEntityLookupsForShortId(shortId, options),
  });
};
