import { getDropdownMenuOption } from "~/utils/menuOptions/getDropdownMenuOption";
export const getDownloadMenuOptions = (param)=>{
    let { onDownload, onExportImage, onSaveToAir } = param;
    const downloadMenuOptions = [
        {
            ...getDropdownMenuOption("save-to-air"),
            onSelect: onSaveToAir,
            type: "item"
        },
        {
            ...getDropdownMenuOption("download"),
            onSelect: onDownload,
            type: "item"
        }
    ];
    if (onExportImage) {
        downloadMenuOptions.push({
            ...getDropdownMenuOption("export-as"),
            onSelect: onExportImage,
            type: "item"
        });
    }
    return downloadMenuOptions;
};
