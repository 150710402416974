import { OtherFiltersType } from '~/store/filters/types';

export const getPublicOtherFilters = ({
  canViewAssetVersions,
  canViewDiscussions,
}: {
  canViewAssetVersions?: boolean;
  canViewDiscussions?: boolean;
}) => {
  const filters: OtherFiltersType[] = [];

  if (canViewAssetVersions) {
    filters.push('has-versions');
  }

  if (canViewDiscussions) {
    filters.push('has-open-comments');
  }

  return filters;
};
