import { canClipBeAnnotated } from '@air/utils-clips';

import { usePublicPermissions } from '~/components/PublicPermissionsProvider';
import { centralizedClipExtSelector, centralizedClipTypeSelector } from '~/store/centralizedClip/selectors';
import { canEditAssetDiscussions, canViewAssetDiscussions } from '~/utils/permissions/assetPermissions';
import { useAirSelector } from '~/utils/ReduxUtils';

export const useCanAnnotateCentralizedPublicClip = () => {
  const { permissions } = usePublicPermissions();
  const type = useAirSelector(centralizedClipTypeSelector);
  const ext = useAirSelector(centralizedClipExtSelector);

  const canViewDiscussions = canViewAssetDiscussions(permissions);
  const canEditDiscussions = canEditAssetDiscussions(permissions);

  const canSeeClipAnnotation =
    canViewDiscussions &&
    canClipBeAnnotated({
      type,
      ext,
    });

  return {
    canSeeClipAnnotation,
    canEditAnnotation: canSeeClipAnnotation && canEditDiscussions,
  };
};
